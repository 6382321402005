/* eslint-disable react/prop-types */
import React from "react";
import { ItemNavLink, Container, Heading, Body, Title, CaseHeadingText, BodyRight } from "./styles";
import { formatDateTime } from "../../utils/helperFunctions";
import { Flow } from "@flow";

export const QueueItem  = ({ path, flow }: { path: string, flow: Flow }) => {
  const { flowDefinitionId, flowId, referenceId, flowNumber, createdAt, data } = flow;

  const createdAtDate = formatDateTime(createdAt);

  // Values for es-loan queue items.
  const firstName = data?.agentApiApplication?.applicant.firstName || " ";
  const lastName = data?.agentApiApplication?.applicant.lastName || " ";
  const agentID = data?.agentApiApplication?.agentId || " ";
  const campaignID = data?.agentApiApplication?.campaignId || " ";
  const nationalID = data?.agentApiApplication?.applicant.nationalId || " ";
  const applicantName = `${firstName} ${lastName}`;

  // Render different values depending on flowDefinitionId
  const isLoan = (flow.flowDefinitionId === "loan-es");

  let heading = (isLoan ? applicantName : flow.flowDefinitionId); // Render BPMN name if not loan process.
  let subTitle = (isLoan ? agentID : "");
  let title = referenceId || flowNumber;

  return (
    <ItemNavLink activeClassName="active" to={`/inbox/${path}/${flowDefinitionId}/${flowId}`}>
      <Container>
        <Heading>
          <CaseHeadingText>{heading}</CaseHeadingText>
          <CaseHeadingText>{createdAtDate}</CaseHeadingText>
        </Heading>
        <Body>
          <div>
            <Title>{title}</Title>
            {subTitle}
          </div>
          <BodyRight>
            <div>{nationalID}</div>
            {campaignID}
          </BodyRight>
        </Body>
      </Container>
    </ItemNavLink>);
};


