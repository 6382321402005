export function isStringNullOrEmpty(value: string | null | undefined) {
  return !value || value === "";
}

const formatCurrency = (amount: number) => {
  return new Intl.NumberFormat("es-ES", { style: "currency", currency: "EUR" }).format(amount ?? 0);
};export { formatCurrency };

export function formatDateTime(date: string) {
  // Date format options for all queue items.
  const options: Intl.DateTimeFormatOptions = {
    dateStyle: "medium", // "full", "long", "medium", or "short"
    timeStyle: "short", // "full", "long", "medium", or "short"
    hour12: false,
  };

  return new Intl.DateTimeFormat("en-GB", options).format(new Date(Date.parse(date))).replace(",", " - ");
}