import React from "react";
import styled from "styled-components";
import { formatDuration, intervalToDuration } from "date-fns";
import { Icons } from "@flow/icons";
import { Modal } from "@flow/modal";
import ManualApplicationReview from "../../tasks/manual-payout-approval";
import ManualPepReview from "../../tasks/manual-pep-review";
import VideoIdView from "../../tasks/video-id";
import { NormalInfoWrapper, SVGArrowWrapper } from "../../tasks/styles";
import { ProcessInfoWrapper, ProcessWrapper, ProcessInfoTitleWrapper, ProcessInfoTitleBold, ProcessIDWrapper } from "../styles";
import { Flow, Statistica } from "@flow";
import { OverviewPersonInfoContactWrapper, OverviewPersonInfoContactAnchor, OverviewSVGGreenWrapper, OverviewSVGBlueWrapper, OverviewLinks } from "./styles";
import { NormalRows } from "../types";

export const LoanOverview = (flow: any) => {
  //State used for opening and closing the ID section
  const [idOpen, setIdOpen] = React.useState(false);

  //States used to view modals for KYC, transactions & video ID
  const [viewManualPayoutModal, setViewManualPayoutModal] = React.useState(false);
  const [viewPepReviewModal, setViewPepReviewModal] = React.useState(false);
  const [viewVideoIdModal, setViewVideoIdModal] = React.useState(false);

  //Variables used to display data
  const data = flow?.caseData?.data;
  const agentApi = data?.agentApiApplication;
  const applicant = agentApi?.applicant;

  const agentId = agentApi?.agentId;
  const effectiveInterestRate = (data?.offer?.insuranceSelected && data?.offer?.effectiveInterestRateWithInsurance) ? data?.offer?.effectiveInterestRateWithInsurance : data?.offer?.effectiveInterestRate;
  const terms = data?.offer?.terms;
  const statistica: Statistica = data?.integrations?.statistica;
  const offerExpirationDate = data?.offer?.validUntil || data?.softOffer?.validUntil;

  // Priority for the loan amount displayed: offer -> softOffer -> agentApiApplication -> hardcoded 0
  const loanAmount = (data?.offer?.finalLoanAmount || data?.softOffer?.loanAmount) || data?.agentApiApplication?.appliedAmount || 0;

  let rejectionRulesTriggeredByStatistica: any[] = [];
  if (statistica) {
    rejectionRulesTriggeredByStatistica = Object.entries(statistica)
      .flatMap(([, value]) => value.policyResults
        ? Object.entries(value.policyResults)
          .filter(([, result]) => result === "Red").map(([policy]) => policy)
        : [])
      .flat();
  }

  //IDs
  const flowId = flow?.caseData?.flowId;
  const externalApplicationId = agentApi?.externalApplicationId;

  //States for handling copying IDs
  const [copiedFlowID, setCopiedFlowID] = React.useState(false);
  const [copiedExternalID, setCopiedExternalID] = React.useState(false);

  //Copies IDs & resets copy states after being active for 1 second
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      if (copiedExternalID) {
        navigator.clipboard.writeText(externalApplicationId);
        setCopiedExternalID(false);
      }
      if (copiedFlowID) {
        navigator.clipboard.writeText(flowId);
        setCopiedFlowID(false);
      }
    }, 1000);

    return () => clearTimeout(timeout);
  }, [copiedExternalID, copiedFlowID]);

  let formattedExpirationDate;
  if (offerExpirationDate) {
    try {
      formattedExpirationDate = formatDuration(
        intervalToDuration({
          start: new Date(),
          end: new Date(offerExpirationDate),
        },
        ),
      );
    } catch (error) {
      formattedExpirationDate = `${offerExpirationDate ? "Offer expired" + Intl.DateTimeFormat("en-GB").format(offerExpirationDate) : "Offer Expired"}`;
    }
  }

  //Renders contact info about the applicant
  const renderPersonInfoWrapper = () => {
    const applicantFirstname = applicant?.firstName;
    const applicantLastname = applicant?.lastName;
    const applicantNIF = applicant?.nationalId;
    const applicantPhone = applicant?.mobileNumber;
    const applicantMail = applicant?.emailAddress;

    return (
      <ProcessInfoWrapper>
        <ProcessInfoTitleWrapper>
          <ProcessInfoTitleBold>{applicantFirstname} {applicantLastname}</ProcessInfoTitleBold>
          {applicantNIF}
        </ProcessInfoTitleWrapper>
        <OverviewPersonInfoContactWrapper>
          <OverviewPersonInfoContactAnchor href={"tel:" + applicantPhone}>{applicantPhone}</OverviewPersonInfoContactAnchor>
          <OverviewPersonInfoContactAnchor href={"mailto:" + applicantMail}>{applicantMail}</OverviewPersonInfoContactAnchor>
        </OverviewPersonInfoContactWrapper>
      </ProcessInfoWrapper>
    );
  };

  const renderIdRows = (config: any[]) => {
    return config.map((item, index) => {
      return (
        <NormalInfoWrapper key={index} style={{ backgroundColor: "rgba(240, 250, 241, 0.5)" }}>
          <div>{item.title}</div>
          <ProcessIDWrapper>
            {item.content}
          </ProcessIDWrapper>
        </NormalInfoWrapper>
      );
    });
  };

  const idConfig = [
    {
      title: "Flow ID",
      content: <div onClick={() => setCopiedFlowID(true)} style={{ display: "flex" }}>
        {flowId}
        {copiedFlowID ?
          <OverviewSVGGreenWrapper><Icons.Check /></OverviewSVGGreenWrapper>
          :
          <OverviewSVGBlueWrapper><Icons.NewTab /></OverviewSVGBlueWrapper>
        }
      </div>,
    },
    {
      title: "External ID",
      content: <div onClick={() => setCopiedExternalID(true)} style={{ display: "flex" }}>
        {externalApplicationId}
        {copiedExternalID ?
          <OverviewSVGGreenWrapper><Icons.Check /></OverviewSVGGreenWrapper>
          :
          <OverviewSVGBlueWrapper><Icons.NewTab /></OverviewSVGBlueWrapper>
        }
      </div>,
    },
  ];

  //Renders the ID section
  const renderId = () => {
    return (
      <>
        <NormalInfoWrapper onClick={() => setIdOpen(!idOpen)} style={{ cursor: "pointer" }}>
          <div>ID</div>
          <SVGArrowWrapper>{!idOpen ? <Icons.ChevronDown /> : <Icons.ChevronUp />}</SVGArrowWrapper>
        </NormalInfoWrapper>
        {idOpen ?
          <>
            {renderIdRows(idConfig)}
          </>
          : null}
      </>
    );
  };

  //Renders one row
  const renderNormalInfoRow = (label: string, value: any) => {
    return (
      <NormalInfoWrapper key={label}>
        <div>{label}</div>
        <div>{value}</div>
      </NormalInfoWrapper>
    );
  };

  //Renders a array of rows
  const renderRows = (normalRows: NormalRows) => {
    return normalRows.map((row) => {
      return renderNormalInfoRow(row.name, row.value);
    });
  };

  let normalRows = [];

  if (agentId)
    normalRows.push({
      name: "Agent",
      value: agentId,
    });

  if (formattedExpirationDate)
    normalRows.push({
      name: data?.businessStatus === "loan-disbursed" ? "Offer expired" : "Offer valid until",
      value: data?.businessStatus === "loan-disbursed" ? "Disbursed" : formattedExpirationDate,
    });

  if (loanAmount)
    normalRows.push({
      name: data?.offer?.finalLoanAmount ? "Loan offered" : "Applied loan amount",
      value: `${new Intl.NumberFormat().format(loanAmount)}  €`,
    });

  if (effectiveInterestRate)
    normalRows.push({
      name: "Effective interest rate",
      value: `${effectiveInterestRate?.toString().replace(".", ",")}%`,
    });

  if (terms !== undefined)
    normalRows.push({
      name: "Terms",
      value: terms,
    });

  normalRows.push({
    name: "Offer given",
    value: data?.offer?.finalLoanAmount ? <Icons.SuccessCircle /> : flow?.caseData?.status === "active" ? <Icons.ActiveCircle /> : <Icons.AbortedCircle />,
  });

  if (rejectionRulesTriggeredByStatistica && rejectionRulesTriggeredByStatistica.length > 0) {
    let rules = rejectionRulesTriggeredByStatistica.map(rule => "• " + rule);

    normalRows.push({
      name: "Rejection reasons from Statistica",
      value: <>{rules.map((el, index) => { return <div key={index} style={{ display: "flex", justifyContent: "flex-end" }}>{el}</div>; })}</>,
    });
  }

  if (applicant?.psD2Data?.accounts && applicant?.psD2Data?.accounts.length > 0)
    normalRows.push({
      name: "Transactions",
      value: <OverviewLinks onClick={() => setViewManualPayoutModal(true)}>View transactions</OverviewLinks>,
    });

  if (data?.integrations?.cm1)
    normalRows.push({
      name: "KYC",
      value: <OverviewLinks onClick={() => setViewPepReviewModal(true)}>View KYC</OverviewLinks>,
    });

  if (data?.integrations?.electronicIdVideoId)
    normalRows.push({
      name: "Video ID",
      value: <OverviewLinks onClick={() => setViewVideoIdModal(true)}>View Video ID</OverviewLinks>,
    });

  return (
    <ProcessWrapper>
      {renderPersonInfoWrapper()}
      {renderId()}
      {renderRows(normalRows)}
      {viewManualPayoutModal ?
        <Modal
          title="Manual Payout Approval"
          size="large"
          onClose={() => setViewManualPayoutModal(false)}
        >
          <ManualApplicationReview flow={flow} />
        </Modal>
        : null}
      {viewPepReviewModal ?
        <Modal
          title="Manual AML / Pep review"
          size="large"
          onClose={() => setViewPepReviewModal(false)}
        >
          <ManualPepReview flow={flow} />
        </Modal>
        : null}
      {viewVideoIdModal ?
        <Modal
          title="Video Id Data from electronic ID"
          size="large"
          onClose={() => setViewVideoIdModal(false)}
        >
          <VideoIdView videoId={data?.integrations?.electronicIdVideoId} signatureId={data?.integrations?.signatureId} flowId={data?.flowId} />
        </Modal>
        : null}
    </ProcessWrapper>
  );
};

export default LoanOverview;
