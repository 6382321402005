import { useQuery } from "react-query";
import { LoadingSpinner } from "../Spinner/LoadingSpinner";
import { downloadAttachment } from "../../utils/fetchAttachments";

type FilePreviewProps = {
  attachmentKey: string;
  mimeType: string;
  flowId: string;
  version?: number;
  colSpan?: number;
};
export function FilePreview({
  attachmentKey,
  mimeType,
  flowId,
  version,
}: FilePreviewProps) {
  const { data: file, isFetching } = useQuery({
    queryKey: ["file", attachmentKey, version, flowId],
    queryFn: () => downloadAttachment(flowId, attachmentKey, mimeType),
  });
  if (isFetching && !file)
    return (
      <div>
        <LoadingSpinner width={24} height={24} />
      </div>
    );
  const isImage = mimeType.includes("image");
  const isPdf = mimeType.includes("pdf");
  return (
    <div>
      {isImage && <img src={file} alt="file" />}
      {isPdf && (
        <iframe
          style={{ minWidth: "80vw", height: "70vh" }}
          title="file"
          src={`${file}#toolbar=0`}
        >
          Fallback to this
        </iframe>
      )}
    </div>
  );
}
