import React from "react";
import { useRef, useState } from "react";
import {
  FlowContext,
  FlowContextProvider,
} from "../../../hooks/useFlowContext";
import { useQuery, QueryClientProvider, QueryClient } from "react-query";
import axios from "axios";
import {
  fetchAttachmentByKey,
  fetchAttachmentsByFlowId,
} from "../../../utils/fetchAttachments";
import UploadFile from "../../../components/Files/FileUpload";
import { CommonUserTaskNew } from "../CommonUserTaskNew";
import { InputList } from "../../../components/Input/InputList";

import type {
  RefinanceFormData,
  ExtendedRefinancingDetails,
  RefinancingDetails,
} from "./types";
import type { ComponentConfig } from "../types";
import { TabButton } from "../../../components/Buttons/TabButton";
import { TabContainer } from "../../../components/Buttons/styles";
import type {
  DynamicTableFileViewProps,
  TableColumn,
} from "../../../components/Table/types";
import DynamicTable, { PdfViewer } from "../../../components/Table/Table";
import GenericButton from "../../../components/Buttons/GenericButton";
import { ApplicantCollection } from "../../../components/Applicant/ApplicantCollection";
import { ListInfo } from "../../../components/Info/ListInfo";
import { RadioButtonGroup } from "../../../components/Decision/RadioButtonGroup";
import { FormTextArea } from "../../../components/Input/FormTextArea";
import ErrorMessages from "../../../components/ErrorMessages/ErrorMessage";
import { uploadFile } from "../../../utils/files";
import { containsLetters } from "../../../utils/containsLetters";
import {
  CollapseableInfoContent,
  CollapseableInfoHeader,
} from "../../../components/Info/styles";
import CloseCircle from "../../../components/Icons/CloseCircle";

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      suspense: false,
      enabled: true,
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      useErrorBoundary: true,
      staleTime: 1000 * 10,
    },
  },
});

const PoaAndRefinanceDocumentation = (props: any) => {
  const { flow, t } = props;
  const { flowId } = flow;

  const decisionErrorMessage = "Please select a decision.";
  const noErrorMessage = "";

  const [showOverview, setShowOverview] = useState(true);
  const [errorMessages, setErrorMessages] = useState<string>(noErrorMessage);
  const [comment, setComment] = useState<string>(
    props.task?.data?.comment ?? "",
  );
  const [decision, setDecision] = useState<boolean | "">(
    props.task?.data?.decision ?? "",
  );
  const [uploadedFiles, setUploadedFiles] = useState<any[]>(
    props.task?.data?.files ?? [],
  );
  const [showFile, setShowFile] = useState<boolean>(false);
  const [openedItem, setOpenedItem] = useState<any>(null);

  const savedrefinanceDetails: ExtendedRefinancingDetails[] =
    props.task?.data?.refinancingDetails;
  const lastRefinanceDetailsId = savedrefinanceDetails
    ? Number.parseInt(
        // The Id of the last refinance detail that where saved
        savedrefinanceDetails[savedrefinanceDetails.length - 1].id,
      )
    : 0;

  const refinanceDetailsId = useRef(lastRefinanceDetailsId);
  const { documents, refinancingDetails, applicantInfo, offer } =
    props.task.context;
  let extendedRefinancingDetails: ExtendedRefinancingDetails[] = [];
  if (!savedrefinanceDetails) {
    extendedRefinancingDetails = refinancingDetails.map(
      (details: ExtendedRefinancingDetails, index: number) => {
        refinanceDetailsId.current++;
        return {
          ...details,
          order: refinanceDetailsId.current,
          id: refinanceDetailsId.current.toString(),
        };
      },
    );
  }

  const [newRefinancingDetails, setNewRefinancingDetails] = useState<
    ExtendedRefinancingDetails[]
  >(savedrefinanceDetails ?? extendedRefinancingDetails);

  const loanDetails = {
    bankAccount: applicantInfo.bankAccountNumber,
    finalLoanAmount: offer.finalLoanAmount,
  };
  let amountSum = 0;

  const handleFileUpload = (files: any[]) => {
    setUploadedFiles(files);
  };

  let formData: RefinanceFormData = {
    refinancingDetails: newRefinancingDetails.map(
      (detail: RefinancingDetails) => {
        const { ...visibleDetails } = detail;
        return visibleDetails;
      },
    ),
    decision: decision,
    comment: comment,
    files: uploadedFiles,
  };

  const onComplete = async () => {
    if (decision === "") {
      setErrorMessages(decisionErrorMessage);
      return;
    }
    if (errorMessages === decisionErrorMessage) {
      setErrorMessages(noErrorMessage);
    }
    if (errorMessages !== noErrorMessage) {
      return;
    }
    if (decision === true && offer.finalLoanAmount - amountSum < 0) {
      setErrorMessages(
        "The refinance amount cannot be higher than total loan amount.",
      );
      return;
    }

    if (uploadedFiles.length > 0) {
      const alreadyUploadedFiles = uploadedFiles.filter((file) => {
        return !!file.fileId;
      });
      const newFiles = uploadedFiles.filter((file) => {
        return !file?.fileId;
      });
      let newFilesResponse = [];
      if (newFiles.length > 0) {
        const { uploadedFilesResponse } = await uploadFile(newFiles);
        newFilesResponse = uploadedFilesResponse;
      }
      formData = {
        refinancingDetails: newRefinancingDetails.map(
          (detail: RefinancingDetails) => {
            const { ...visibleDetails } = detail;
            return visibleDetails;
          },
        ),
        decision: decision,
        comment: comment,
        files: newFilesResponse.concat(alreadyUploadedFiles),
      };
    } else {
      formData = {
        refinancingDetails: newRefinancingDetails.map(
          (detail: RefinancingDetails) => {
            const { ...visibleDetails } = detail;
            if (visibleDetails.amount === "") {
              visibleDetails.amount = 0;
            }
            return visibleDetails;
          },
        ),
        decision: decision,
        comment: comment,
      };
    }
    props.complete(
      formData,
      () => {},
      (e: any) => {
        setErrorMessages(e?.detail);
      },
    );
  };

  const onSave = async () => {
    if (uploadedFiles.length > 0) {
      const alreadyUploadedFiles = uploadedFiles.filter((file) => {
        return !!file.fileId;
      });
      const newFiles = uploadedFiles.filter((file) => {
        return !file?.fileId;
      });
      let newFilesResponse = [];
      if (newFiles.length > 0) {
        const { uploadedFilesResponse } = await uploadFile(newFiles);
        newFilesResponse = uploadedFilesResponse;
      }
      formData = {
        refinancingDetails: newRefinancingDetails.map(
          (detail: RefinancingDetails) => {
            const { ...visibleDetails } = detail;
            return visibleDetails;
          },
        ),
        decision: decision,
        comment: comment,
        files: newFilesResponse.concat(alreadyUploadedFiles),
      };
    } else {
      formData = {
        refinancingDetails: newRefinancingDetails.map(
          (detail: RefinancingDetails) => {
            const { ...visibleDetails } = detail;
            return visibleDetails;
          },
        ),
        decision: decision,
        comment: comment,
        files: uploadedFiles,
      };
    }
    props.save(
      formData,
      () => console.log("success"),
      (error: any) => console.error({ error }),
      true,
    );
  };

  const addNewInput = () => {
    refinanceDetailsId.current++;
    infoConfig = handleClickAddNewButton(
      refinanceDetailsId.current,
      setNewRefinancingDetails,
      infoConfig,
      handleDelete,
      setErrorMessages,
    );
  };

  const formConfig: ComponentConfig[] = [
    {
      component: (
        <ApplicantCollection
          nationalId={applicantInfo.nationalId}
          firstName={applicantInfo.firstName}
          lastName={applicantInfo.lastName}
          mobileNumber={applicantInfo.mobileNumber}
          emailAddress={applicantInfo.emailAddress}
        />
      ),
      order: 1,
    },
    {
      component: <ListInfo title="Loan details" context={loanDetails} />,
      order: 2,
    },
    {
      component: (
        <RadioButtonGroup
          radioButtonValue={decision}
          onChange={(e: string) =>
            e === "true" ? setDecision(true) : setDecision(false)
          }
          title="Verify refinance details"
          options={[
            { value: true, label: "Approve - send to disbursement" },
            { value: false, label: "Reject application" },
          ]}
          id="verify-refinance"
        />
      ),
      order: 3,
    },
    {
      component: (
        <FormTextArea
          name="Comment"
          onChange={(e: string) => setComment(e)}
          label="Comment"
          defaultValue={comment}
        />
      ),
      order: 4,
    },
    {
      component: <ErrorMessages errors={[errorMessages]} />,
      order: 7,
      hide: errorMessages === noErrorMessage,
    },
  ];
  let infoConfig: ComponentConfig[] = [];
  const { data: files } = useQuery({
    queryKey: ["files", flowId],
    queryFn: async () => {
      try {
        const attachmentsWithVersions = await Promise.all(
          documents.map(
            async (file: {
              fileName: string;
              name: string;
              fileId: string;
            }) => {
              let data;
              try {
                if (file.fileId === "") {
                  return {
                    attachmentKey: file.name,
                    owner: "",
                    filename: file.fileName,
                    hasFailed: true,
                  };
                }
                data = await fetchAttachmentByKey(flowId, file.name);
              } catch (err) {
                if (axios.isAxiosError(err)) {
                  data = null;
                }
              }
              const version = data?.attachment;
              if (!version) {
                console.error("failed to fetch attachment:", file);
                return {
                  attachmentKey: file.name,
                  owner: "",
                  filename: file.fileName,
                  hasFailed: true,
                };
              }
              if (version.previousVersions) {
                const versions = [{ attachmentKey: file.name, ...version }];
                for (const element of Object.values(version.previousVersions)) {
                  if (typeof element === "object" && element !== null) {
                    versions.push({
                      attachmentKey: file.name,
                      ...element,
                    });
                  }
                }
                return versions;
              }
              return { attachmentKey: file.name, ...version };
            },
          ),
        );

        return {
          flowId,
          attachments: attachmentsWithVersions
            .flat()
            .sort((a, b) => b.version - a.version),
        };
      } catch (error) {}
    },
  });

  const handleDelete = (idToDelete: string | undefined) => {
    setNewRefinancingDetails((prevDetails) =>
      prevDetails.filter((detail) => {
        return detail.id !== idToDelete;
      }),
    );
    infoConfig = removeComponent(idToDelete, infoConfig);
  };

  const handleTabView = (tab: string) => {
    if (tab === "Overview") {
      setShowOverview(true);
    }
    if (tab === "Files") {
      setShowOverview(false);
    }
  };

  const removeComponent = (
    idToDelete: string | undefined,
    infoConfig: ComponentConfig[],
  ) => {
    const newInfoConfig = infoConfig.filter((config) => {
      if (config.component && React.isValidElement(config.component)) {
        if (config.component.type !== InputList) {
          return true;
        }
        const props = config.component.props;
        if (
          Array.isArray(props.infoLists) &&
          props.infoLists[0]?.id !== idToDelete
        ) {
          return true;
        }
      }
      return false;
    });

    return newInfoConfig;
  };

  const handleOnBlur = (
    key: string,
    e: React.FocusEvent<HTMLInputElement>,
    id: string | undefined,
    setErrorMessages: (value: React.SetStateAction<string>) => void,
    setNewRefinancingDetails: (
      value: React.SetStateAction<ExtendedRefinancingDetails[]>,
    ) => void,
  ) => {
    let value: string | number = e.target.value;
    switch (key) {
      case "amount":
        if (containsLetters(value)) {
          setErrorMessages(`Illegal characters in ${value}.`);
        } else {
          setErrorMessages("");
        }
        // Replace comma with dot, before parsing to float and rounding up to 2 decimals. E.g: 12,178 => 12.18
        value = Number.parseFloat(value.replace(",", ".")).toFixed(2);
        break;
      default:
        break;
    }

    setNewRefinancingDetails((prevDetails: ExtendedRefinancingDetails[]) => {
      const updatedDetails = prevDetails.map((detail) => {
        if (detail.id !== id) {
          return detail;
        }
        return {
          ...detail,
          [key]: value,
        };
      });
      return updatedDetails;
    });
  };

  const handleClickAddNewButton = (
    refinanceDetailsId: number,
    setNewRefinancingDetails: (
      value: React.SetStateAction<ExtendedRefinancingDetails[]>,
    ) => void,
    infoConfig: ComponentConfig[],
    handleDelete: (id: string) => void,
    setErrorMessages: (value: React.SetStateAction<string>) => void,
  ) => {
    const newObject: ExtendedRefinancingDetails = {
      accountNumber: "",
      bankName: "",
      id: refinanceDetailsId.toString(),
      paymentReference: "",
      amount: "",
      order: refinanceDetailsId,
    };
    const { id, order, ...visibleDetails } = newObject;
    const context = visibleDetails;

    setNewRefinancingDetails((prevDetails) => [...prevDetails, newObject]);
    const newInfoConfig: ComponentConfig[] = [
      ...infoConfig,
      {
        component: (
          <InputList
            deletable={true}
            onClick={() => handleDelete(id)}
            title={"Refinance details"}
            infoLists={[
              {
                title: "",
                id: id,
                context: context,
                onBlur: (key, e) =>
                  handleOnBlur(
                    key,
                    e,
                    id,
                    setErrorMessages,
                    setNewRefinancingDetails,
                  ),
              },
            ]}
          />
        ),
        order: order,
      },
    ];
    return newInfoConfig;
  };

  const mapAttachmentsToPdfTableProp = (attachments: any) => {
    if (!attachments) {
      return [];
    }
    const mappedAttachements: DynamicTableFileViewProps[] = attachments.map(
      (attachment: any) => {
        return {
          type: attachment.filetype,
          fileName: attachment.filename,
          uploadedAt: new Date(
            attachment.updatedAt ?? attachment.createdAt,
          ).toLocaleDateString("no-NO", {
            hour: "numeric",
            minute: "numeric",
          }),
          attachment: {
            attachmentKey: attachment.attachmentKey,
            attachmentId: attachment.attachmentId,
            flowId: flowId,
            mimeType: attachment.mimetype,
            version: attachment.version,
          },
        };
      },
    );
    return mappedAttachements;
  };

  const columns: TableColumn[] = [
    { title: "Type", key: "type", attachment: "attachment" },
    { title: "Filename", key: "fileName" },
    { title: "Uploaded At", key: "uploadedAt" },
    { title: "", key: "attachment" },
  ];

  const createOverviewTab = () => {
    let inputOrder = 0;
    if (newRefinancingDetails.length === 0) {
      refinanceDetailsId.current++;
      const emptyInputFields: RefinancingDetails = {
        accountNumber: "",
        bankName: "",
        paymentReference: "",
        amount: "",
      };
      newRefinancingDetails.push({
        ...emptyInputFields,
        id: refinanceDetailsId.current.toString(),
        order: 0,
      });
    }

    for (const [, details] of newRefinancingDetails.entries()) {
      inputOrder++;
      const { id, order, ...visibleDetails } = details;
      amountSum += visibleDetails.amount === "" ? 0 : visibleDetails.amount; // if amount is empty string, then add 0. Else add the amount
      const input = (
        <InputList
          deletable={true}
          onClick={() => handleDelete(id)}
          title={"Refinance details"}
          infoLists={[
            {
              title: "",
              context: visibleDetails,
              onBlur: (key, e) =>
                handleOnBlur(
                  key,
                  e,
                  id,
                  setErrorMessages,
                  setNewRefinancingDetails,
                ),
              id: id,
            },
          ]}
        />
      );

      infoConfig.push({ component: input, order: inputOrder });
    }

    infoConfig.push({
      component: <GenericButton onClick={addNewInput} type="AddNewButton" />,
      order: inputOrder + 1,
    });
  };

  // TODO: figure out what to do with the tables.
  // Is the caseworker table needed as the documents will only show there when the task is completed?
  // meaning it will always be empty while this task is active.
  const createFilesTab = () => {
    infoConfig.push(
      {
        component: createFilesTableComponent(
          files?.attachments.filter((file) => {
            // only show files where the attachmentId has value. If not it means the attachment has been removed.
            return !!file?.attachmentId;
          }),
          "Documents uploaded by customer",
        ),
        order: 1,
        hide: files?.attachments?.length === 0,
      },
      // {
      //   component: createFilesTableComponent(
      //     uploadedFiles,
      //     "Documents uploaded by caseworker",
      //   ),
      //   order: 2,
      //   hide: uploadedFiles?.length === 0,
      // },
      {
        component: (
          <div>
            <UploadFile
              onFileUpload={handleFileUpload}
              uploadedFiles={uploadedFiles}
            />
          </div>
        ),
        order: 3,
      },
    );
  };

  const setColumnsForUserTask = () => {
    if (showOverview && infoConfig.length > 2) {
      return 2;
    }
    if (showOverview && infoConfig.length === 2) {
      return 1;
    }

    if (!showOverview) {
      return 1;
    }
  };

  const createFilesTableComponent = (
    files: DynamicTableFileViewProps[] | undefined,
    title: string,
  ) => {
    const handleRowClick = (item: DynamicTableFileViewProps) => {
      setShowFile(true);
      setOpenedItem(item);
    };

    return (
      <div>
        <FlowContextProvider value={{ flow }}>
          <FlowContext.Provider value={props}>
            <DynamicTable
              handleRowClick={(item) => handleRowClick(item)}
              data={mapAttachmentsToPdfTableProp(files) ?? []}
              columns={columns}
              flowId={flowId}
              mainHeader={title}
            />
          </FlowContext.Provider>
        </FlowContextProvider>
      </div>
    );
  };

  if (showOverview) {
    createOverviewTab();
  } else {
    createFilesTab();
  }

  return showFile ? (
    <div
      style={{
        maxWidth: "90vw",
        maxHeight: "80vh",
        padding: "10px",
      }}
    >
      <CollapseableInfoHeader style={{ borderRadius: "8px" }}>
        {openedItem.fileName}
        <CloseCircle onClick={() => setShowFile(false)} />
      </CollapseableInfoHeader>
      <CollapseableInfoContent>
        <PdfViewer item={openedItem} shouldShow={true} flowId={flowId} />
      </CollapseableInfoContent>
    </div>
  ) : (
    <div>
      <TabContainer>
        <TabButton
          onClick={() => handleTabView("Overview")}
          label={"Overview"}
          selected={showOverview}
        />
        <TabButton
          onClick={() => handleTabView("Files")}
          label={"Files"}
          selected={!showOverview}
        />
      </TabContainer>
      <CommonUserTaskNew
        handleComplete={onComplete}
        handleSave={onSave}
        infoConfig={infoConfig}
        formConfig={formConfig}
        overrideMasonryColumns={setColumnsForUserTask()}
      />
    </div>
  );
};

const AppWrapper = (props: any) => (
  <QueryClientProvider client={queryClient}>
    <PoaAndRefinanceDocumentation {...props} />
  </QueryClientProvider>
);

export default AppWrapper;
