import { LoanOverview } from "./loan";
import { CleanupOverview } from "./cleanup";
import { Flow } from "@flow";

const ProcessOverview = (flow: Flow) => {
  if (flow.flowDefinitionId === "loan-es") {
    return LoanOverview(flow);
  }

  if (flow.flowDefinitionId === "cleanup" || flow.flowDefinitionId === "manual-cleanup") {
    return CleanupOverview(flow);
  }

  return `The "${flow.flowDefinitionId}" process is missing a dedicated component in src/components/overviewTab folder.`;
};

export default ProcessOverview;
