import styled from 'styled-components';

export const OverviewSVGBlueWrapper = styled.div`
    rect {
        fill: #2969FF;
    }
`;

export const OverviewSVGGreenWrapper = styled.div`
    rect {
        fill: green;
    }
`;

export const OverviewPersonInfoContactWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding: 15px 25px;
`;

export const OverviewPersonInfoContactAnchor = styled.a`
    color: #2F80ED;
    font-weight: 400;
    text-decoration: none;
`;

export const OverviewLinks = styled.a`
    text-decoration: underline;
    color: #2F80ED;
    font-weight: 400;
    font-size: 14px;
    cursor: pointer;
`;