/* eslint-disable react/prop-types */
import React from "react";
import {
	DownloadFileWrapper,
	FileHeader,
	FileName,
	FileGroup,
	StyledCollapsible,
	File,
	Container,
	TimeStamp,
	DownloadButtonArea,
} from "./styles";
import { DownloadAttachmentButton } from "@flow/components";
import { renderDownloadButton } from "../../components/Buttons/DownloadButton";
import { Flow, Attachment } from "@flow";

const timeFormat: Intl.DateTimeFormatOptions = {
	year: "numeric",
	month: "numeric",
	day: "numeric",
	hour: "numeric",
	minute: "numeric",
	second: "numeric",
	hour12: false,
};

export const AttachmentsTab = ({ flow }: { flow: Flow }) => {
	const attachments = flow?.data?.attachments;

	const camelCaseToProperCaseConversion = (fileName: string) => {
		const words = fileName.split(/(?=[A-Z])/);
		const properCaseWords = words.map(
			(word) => word.charAt(0).toUpperCase() + word.slice(1),
		);
		return properCaseWords.join(" ");
	};

	return (
		<Container>
			{
				// Merge attachments and uniqueDownPaymentPlans
				Object.entries({ ...attachments })
					// Sort the attachments by the date they were uploaded: newest first
					.sort(([, a], [, b]) => new Date(b.createdAt).getTime() - new Date(a.createdAt).getTime())
					.map(([fileName, fileData]) => {
						return (
							<FileGroup key={fileName}>
								<FileHeader>
									<StyledCollapsible
										isOpen={true}
										header={camelCaseToProperCaseConversion(fileName)}
									>
										<br />
										<File>
											<p>Filename</p>
											<p>Uploaded</p>
										</File>
										<File key={fileData.title}>
											<FileName>{fileData?.filename}</FileName>
											<TimeStamp>
												{new Intl.DateTimeFormat("default", timeFormat).format(
													new Date(fileData.createdAt),
												)}
											</TimeStamp>
										</File>
										<DownloadButtonArea>
											<DownloadFileWrapper>
												{renderDownloadButton(flow.flowId, fileName, fileData)}
											</DownloadFileWrapper>
										</DownloadButtonArea>
									</StyledCollapsible>
								</FileHeader>
							</FileGroup>
						);
					})
			}
		</Container>
	);
};

export const ErrorsAttachmentsTab = ({ flow }: { flow: Flow }) => {
	const attachments = flow.data.attachments;
	// const filteredAttachments = Object.filter(
	// 	attachments,
	// 	([fileName, fileData]) => fileData.filename.slice(0, 6) === "Errors",
	// );
	const filteredAttachments = Object.fromEntries(
		Object.entries(attachments).filter(
		  ([fileName, fileData]) => fileData.filename.slice(0, 6) === "Errors"
		)
	  );
	return (
		<Container>
			{Object.entries(filteredAttachments).map(([fileName, fileData]) => {
				return (
					<FileGroup key={fileName}>
						<FileHeader>
							<StyledCollapsible header={fileName}>
								<File>
									<p>{"File Name"}</p>
									<p>{"Uploaded"}</p>
								</File>
								<File key={fileData.title}>
									<FileName>{fileData?.filename}</FileName>
									<TimeStamp>
										{new Intl.DateTimeFormat("default", timeFormat).format(
											new Date(fileData.createdAt),
										)}
									</TimeStamp>
									<DownloadFileWrapper>
										<DownloadAttachmentButton
											flowId={flow.flowId}
											attachmentKey={fileName}
										/>
									</DownloadFileWrapper>
								</File>
							</StyledCollapsible>
						</FileHeader>
					</FileGroup>
				);
			})}
		</Container>
	);
};
