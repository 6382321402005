import type React from "react";
import { useState, useEffect } from "react";
import { camelCaseToWords } from "../../utils/camelCaseToWords";
import {
  InputListContainer,
  InputListHeader,
  InputListTitle,
  PropertyContainer,
  PropertyLabel,
  PropertyValueDropDownMenu,
  PropertyValueInput,
} from "./styles";
import DeleteIcon from "../Icons/Delete";
import type {
  DropDownFieldOptions,
  InputListProps,
  RenderInfoProps,
  RenderPropertiesProps,
} from "./types";
import type { Option } from "../DropDownMenu/types";

const RenderProperties = (props: RenderPropertiesProps) => {
  const [localProperties, setLocalProperties] = useState(props.properties);

  useEffect(() => {
    setLocalProperties(props.properties);
  }, [props.properties]);

  const PropertyKeys = Object.keys(localProperties);
  return (
    <>
      {Object.entries<string | number | null | DropDownFieldOptions>(
        localProperties,
      ).map(([key, value], index) => {
        const isLast = key === PropertyKeys[PropertyKeys.length - 1];
        const valueLength = !value ? 0 : value.toString().length;
        return (
          <PropertyContainer key={localProperties.id ?? index} isLast={isLast}>
            <PropertyLabel>{camelCaseToWords(key)}</PropertyLabel>
            {typeof value === "object" && !!value ? (
              <PropertyValueDropDownMenu
                style={{}}
                value={value.defaultValue ?? ""}
                options={value.options}
                onChange={(e: React.FocusEvent<HTMLInputElement, Element>) => {
                  props.onBlur(key, e);
                  setLocalProperties({
                    ...localProperties,
                    [key]: {
                      defaultValue: e.target.value,
                      options: value.options,
                    },
                  });
                }}
              />
            ) : (
              <PropertyValueInput
                onChange={(e: any) =>
                  setLocalProperties({
                    ...localProperties,
                    [key]: e.target.value,
                  })
                }
                onBlur={(e: any) => props.onBlur(key, e)}
                defaultValue={value ?? ""}
                onClick={(e: any) => {
                  e.preventDefault();
                }}
                inputValueLength={valueLength}
              />
            )}
          </PropertyContainer>
        );
      })}
    </>
  );
};

const RenderInfo = (props: RenderInfoProps): JSX.Element => {
  if (props.hide) {
    return <></>;
  }
  return (
    <div style={{ backgroundColor: "#FFF", paddingBottom: "16px" }}>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "24px 16px 8px 16px",
          color: "#787573",
        }}
      >
        {props.title ? (
          <>
            <div>{props.title}</div>
            <div>VALUE</div>
          </>
        ) : null}
      </div>
      <RenderProperties properties={props.context} onBlur={props.onBlur} />
    </div>
  );
};

/**
 * Renders a list of input components.
 * @component
 * @param {Object} props - The component props.
 * - title - The title of the input list.
 * - infoLists - The list of information to display.
 * - deletable - Indicates if the list is deletable.
 * - onClick - The function to handle the delete button click.
 * - task - The task which the input list is displayed in. Used for special cases.
 * @returns {JSX.Element} The rendered component.
 */
export const InputList: React.FC<InputListProps> = (props: InputListProps) => {
  return (
    <InputListContainer>
      <InputListHeader>
        <InputListTitle>
          <div>{props.title}</div>
          {props.deletable && <DeleteIcon onClick={props.onClick} />}
        </InputListTitle>
      </InputListHeader>
      {props.infoLists.map((info, index) => (
        <RenderInfo
          key={info.id ?? index}
          title={info.title}
          context={info.context}
          onBlur={info.onBlur}
          hide={info.hide}
        />
      ))}
    </InputListContainer>
  );
};
