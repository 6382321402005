import type React from "react";
import { useState, useRef } from "react";
import GenericButton from "../Buttons/GenericButton";
import DeleteIcon from "../Icons/Delete";

interface UploadFileProps {
  onFileUpload: (files: File[]) => void;
  uploadedFiles?: any[];
}

const UploadFile: React.FC<UploadFileProps> = ({
  onFileUpload,
  uploadedFiles,
}) => {
  const [selectedFiles, setSelectedFiles] = useState<any[]>(
    uploadedFiles ?? [],
  );
  const fileInputRef = useRef<HTMLInputElement>(null);

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFiles = event.target.files;
    if (newFiles) {
      const filesArray = Array.from(newFiles);
      setSelectedFiles((prevFiles) => [...prevFiles, ...filesArray]);
      onFileUpload([...selectedFiles, ...filesArray]);
    }
  };

  const handleRemoveFile = (fileToRemove: any) => {
    const updatedFiles = selectedFiles.filter((file) => file !== fileToRemove);
    setSelectedFiles(updatedFiles);
    onFileUpload(updatedFiles);
  };

  const handleButtonClick = () => {
    fileInputRef.current?.click();
  };

  return (
    <div>
      <GenericButton onClick={handleButtonClick} type="UploadDocumentButton" />
      <input
        type="file"
        accept=".jpg,.jpeg,.png,.pdf"
        onChange={handleFileChange}
        multiple
        style={{ display: "none" }}
        ref={fileInputRef}
      />
      {selectedFiles.length > 0 && (
        <div>
          <p>Selected files:</p>
          <ul>
            {selectedFiles.map((file, index) => (
              <div style={{ display: "flex", gap: "5px" }}>
                <li key={index}>
                  {file.filename ? file.filename : file.name}{" "}
                </li>
                <DeleteIcon onClick={() => handleRemoveFile(file)} />
              </div>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default UploadFile;
