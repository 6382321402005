import { Select } from "@flow/forms";
import styled from "styled-components";
import type { PropertyContainerProps, PropertyValueInputProps } from "./types";

export const FormTextListContainer = styled.div`
  padding-right: 28px;
  display: flex;
  gap: 8px;
  color: #1e1e1e;
  align-items: flex-start;
`;

export const PropertyContainer = styled.div<PropertyContainerProps>`
    border-bottom: ${(props) => (props.isLast ? "none" : "1px solid #e9e9e8")};
    display: flex;
    justify-content: space-between;
    color: #2b2b2b;
    padding-right: 16px;
`;

export const PropertyLabel = styled.div`
  padding: 12px 0 12px 16px;
`;

export const PropertyValueInput = styled.input<PropertyValueInputProps>`
  min-width: ${(props) => `${props.inputValueLength}ch`};
  background-color: #fafafa;
  border: 1px solid #e9e9e8;
  padding-right: 8px;
  cursor: text;
  text-align: end;
`;

export const PropertyValueDropDownMenu = styled(Select)`
  background-color: #fafafa;
  border: 1px solid #e9e9e8;
  padding-right: 8px;
  cursor: text;
  text-align: end;
  height: 100%;
`;

export const InputListContainer = styled.div`
  border: 1px solid #d4dcec;
  border-radius: 8px;
  min-width: 395px;
  overflow: hidden;
`;

export const InputListHeader = styled.div`
  border-bottom: 1px solid #d4dcec;
  background-color: #f8f9fc;
  color: #1e1e1e;
`;

export const InputListTitle = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 8px 0 8px 16px;
  font-weight: 600;
  font-size: 16px;
`;
