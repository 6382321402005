import styled from "styled-components";
import { getColorForEnvironment } from "../tasks/styles";

export const ProcessWrapper = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #FFFFFF;
    box-shadow: 0px 15px 40px rgba(184, 184, 184, 0.2), 0px 5px 10px rgba(184, 184, 184, 0.05);
    border-radius: 5px;
`;

export const ProcessInfoWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 65px;
    font-size: 14px;
    background-color: ${getColorForEnvironment()};
`;

export const ProcessInfoTitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    padding: 15px 25px;
    color: #25562B;
    font-weight: 300;
`;

export const ProcessInfoTitleBold = styled.b`
    font-weight: 600;
    font-size: 16px;
`;

export const ProcessIDWrapper = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`;
